import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
    {
        path: '/daili',
        name: 'daili',
        meta: { title: '代理' },
        redirect: '/daili',
        component: Main,
        children: [
            {
              path: '/daili',
              name: 'daili',
              meta: {
                title: '代理列表',
                parentPath: '/daili',
                permission: ['view']
              },
              component: () => import('@/views/setting/proxy_user/lists.vue')
            },
            {
              path: '/daili/form',
              name: 'daili_form',
              meta: {
                hidden: true,
                title: '新建、编辑代理',
                parentPath: '/daili',
                prevPath: '/daili',
                permission: ['view']
              },
              component: () => import('@/views/setting/proxy_user/edit_form.vue')
            },
          ]
    }
]

export default routes
